button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

body {
  font-family: "Chivo";
  color: #4a2c2a;
  background-color: #fffff2;
}

.hero {
  width: 100%;
  height: 600px;
  background-color: #e0c529;
}

.section {
  width: 100%;
  background-color: #fffff2;
}

nav {
  font-style: italic;
  width: 95%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
}

nav ul {
  display: flex;
  flex-wrap: wrap;
}

nav ul li,
h3 {
  color: #4a2c2a;
  font-family: Chivo;
  font-style: italic;
  list-style-type: none;
  padding-right: 10px;
  text-transform: uppercase;
  font-size: 16px;
}

.web-title h1 {
  font-family: "Bigshot One";
  font-style: none;
  font-size: 85.34px;
  text-align: center;
}

.hero h2 {
  font-style: italic;
  font-size: 16px;
  font-weight: 300;
  margin: 0 auto;
  text-align: center;
}

.web-title {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-evenly;
  margin-top: 120px;
  align-items: center;
  height: 200px;
}

.left-spacer,
.right-spacer {
  border-top: 8px solid #4a2c2a;
  border-bottom: 8px solid #4a2c2a;
  width: 100%;
  height: 115px;
  position: relative;
}

.left-spacer div,
.right-spacer div {
  margin: 5px 0px 5px 0px;
  border-top: 3px solid #4a2c2a;
  border-bottom: 3px solid #4a2c2a;
  width: 100%;
  height: 100px;
}
.inline-menu li {
  padding: 3px 8px;
}

.inline-menu li:hover {
  border: 1px solid #4a2c2a;
  border-radius: 50%;
}

.discogs-button {
  width: 120px;
  height: 120px;
  background-color: #a83b10;
  border-radius: 50%;
  position: relative;
  left: 10%;
  bottom: 12%;
}

.discogs-button:hover {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  /* transform: rotate(3deg); */
  /* transform: rotate(0.3rad);/ */
  /* transform: rotate(3grad); */
  /* transform: rotate(.03turn);  */
}

#gigs {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0px;
}

#gigs h2 {
  font-weight: 300;
  font-style: italic;
  border: 1px solid #4a2c2a;
  border-radius: 50%;
  padding: 20px;
  width: 100px;
  text-align: center;
}

.gig-list h3 {
  text-align: center;
}

.gig-item {
  text-align: center;
  line-height: 11px;
}
.gig-datetime {
  font-family: "Bigshot One";
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
}

.gig-toggle {
  cursor: pointer;
  color: rgb(168, 59, 16);
  text-align: center;
  font-style: italic;
  font-size: 12px;
}

.gig-venue,
.gig-address {
  font-weight: 300;
  font-style: italic;
}

.gig-venue {
  text-transform: lowercase;
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

.gig-item {
  padding: 10px 0px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.footer {
  width: 100%;
  height: 20px;
  padding: 10px 0px;
  text-align: center;
  background-color: #a83b10;
}

.footer span {
  color: #fffff2;
  font-style: italic;
  text-transform: uppercase;
}

@media all and (min-width: 1024px) {
  html,
  body {
    overflow-x: hidden;
  }
  body {
    position: relative;
  }

  .menu {
    display: none;
  }
}

@media all and (min-width: 769px) {
  .menu {
    display: none;
  }

  .column-menu {
    display: none;
  }
}

@media all and (max-width: 768px) {
  .hero {
    height: 800px;
  }

  .inline-menu {
    display: none;
  }
  .hero h1 {
    font-size: 61.34px;
  }
  .hero h2 {
    font-style: italic;
    font-size: 16px;
    font-weight: 300;
    margin: 0 auto;
    text-align: center;
  }
  .menu {
    z-index: 10;
    padding: 10px;
    width: 100px;
    border: 1px solid #e0c529;
    border-radius: 50%;
    transition: 0.3s;
  }

  .menu:hover {
    border: 1px solid #4a2c2a;
    border-radius: 50%;
  }

  .nav-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .nav-right .column-menu {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    margin-top: 5px;
    padding: 10px;
  }

  .nav-right ul li:hover {
    border-bottom: 1px solid #4a2c2a;
  }

  .left-spacer div,
  .right-spacer div {
    height: 65px;
  }

  .left-spacer,
  .right-spacer {
    height: 81px;
  }

  .discogs-button {
    width: 120px;
    height: 120px;
    background-color: #a83b10;
    border-radius: 50%;
    position: relative;
    left: 5%;
    bottom: -8%;
  }
}
